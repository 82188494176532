import axios from "axios";

class GeneralService {
  public async getURLContents(url: string): Promise<any> {
    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      return error.response;
    }
  }
}

export default new GeneralService();
