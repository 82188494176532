import TranslationContext from "./TranslationContext";

const TranslationProvider = ({ children, translations }) => {
  return (
    <TranslationContext.Provider value={translations}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;
