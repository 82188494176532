import settingsService from "../settingsService";
import UserService from "../user/UserService";

export class Facebook {
  static async query(fields: string) {
    const { fb_ig_account_id, fbToken } =
      await UserService.getUserAccessTokenToUse();

    const version = "v10.0";
    const host = "https://graph.facebook.com";

    const url = `${host}/${version}/${fb_ig_account_id}?fields=${fields}&&access_token=${fbToken}`;

    const response = await fetch(url, { method: "GET" });
    let result = await response.text();

    if (!response.ok) {
      try {
        if (result.includes("limit")) {
          const appUsage = response.headers.get("x-app-usage");
          if (appUsage) {
            const usage = JSON.parse(appUsage);

            console.log("Current App Usage:", usage);

            const CALL_COUNT_THRESHOLD = 95;
            const TOTAL_TIME_THRESHOLD = 95;

            if (
              usage.call_count > CALL_COUNT_THRESHOLD ||
              usage.total_time > TOTAL_TIME_THRESHOLD
            ) {
              console.log(
                `Threshold exceeded. Pausing fetching... usage: ${JSON.stringify(
                  usage
                )}`
              );
              settingsService.pauseFetching();
            }
          }
        }
      } catch (e) {
        console.error("Error while checking limits:", e);
      }
    }

    return JSON.parse(result);
  }
}
