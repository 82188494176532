import { SignJWT, jwtVerify } from "jose";

const SECRET_KEY = process.env.JWT_SECRET_KEY;

export const signToken = async (userData: any) => {
  const token = await new SignJWT(userData)
    .setProtectedHeader({ alg: "HS256" })
    .setExpirationTime("360h") // 15 days
    .sign(new TextEncoder().encode(SECRET_KEY));

  return token;
};

export const verifyToken = async (token: string) => {
  try {
    const { payload } = await jwtVerify(
      token,
      new TextEncoder().encode(SECRET_KEY)
    );
    return payload;
  } catch (err) {
    console.error("Error verifying token:", err);
    return null;
  }
};

export const signOut = async (res: any) => {
  res.setHeader(
    "Set-Cookie",
    `token=; HttpOnly; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT`
  );
};

export const createSessionCookie = async (
  res: any,
  user: any,
  userType: string
) => {
  let appToken = await signToken({
    email: user.email,
    uuid: user.uuid,
    name: user.name,
    user_uuid: user.user_uuid,
    accounts: user.accounts || [],
    userType: userType,
  });

  res.setHeader("Set-Cookie", `token=${appToken}; HttpOnly; Path=/;`);

  return res;
};
