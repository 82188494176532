import { Prisma, PrismaClient } from "@prisma/client";

declare global { 
  var prisma: PrismaClient | undefined;
}

const prisma = global.prisma || new PrismaClient();

if (process.env.NODE_ENV !== "production") {
  global.prisma = prisma;
}

export default prisma;

export interface Paginated {
  data: any;
  total: number;
  page: number;
  totalPages: number;
  pageSize: number;
  hasNext: boolean;
  hasPrevious: boolean;
}

export async function paginateModel(
  model: any,
  where: any,
  page: number = 1,
  pageSize: number = 10,
  orderBy: any = {
    id: "desc",
  }
): Promise<Paginated> {
  let query = {
    skip: (page - 1) * pageSize,
    take: pageSize,
    orderBy,
  } as any;

  if (where) {
    query = {
      ...query,
      where,
    };
  }
  const modelData = await model.findMany(query);
  const totalCount = await model.count();

  return {
    data: modelData,
    total: totalCount,
    page: page,
    pageSize: pageSize,
    hasNext: page * pageSize < totalCount,
    hasPrevious: page > 1,
    totalPages: Math.ceil(totalCount / pageSize),
  };
}

export async function getPaginated(
  model: any,
  query: any,
  page: number = 1,
  pageSize: number = 10
): Promise<Paginated> {
  query = {
    ...query,
    skip: (page - 1) * pageSize,
    take: pageSize,
  } as any;

  const modelData = await model.findMany(query);
  const totalCount = await model.count();

  return {
    data: modelData,
    total: totalCount,
    page: page,
    pageSize: pageSize,
    hasNext: page * pageSize < totalCount,
    hasPrevious: page > 1,
    totalPages: Math.ceil(totalCount / pageSize),
  };
}
 