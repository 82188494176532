import { DownloadedFile } from "@/interfaces/files";
import axios from "axios";
import Jimp from "jimp";
import path from "path";
import stream from "stream";
import { v4 as uuid } from "uuid";
import AWS from "aws-sdk";
let fs: any;
let fsp: any;
if (typeof window === "undefined") {
  fs = require("fs");
  fsp = require("fs/promises");
}

AWS.config.update({
  accessKeyId: process.env.AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
  region: process.env.AWS_S3_REGION,
});

export interface ImageResizeProperties {
  resizeWidth: number;
  aspectRation: { width: number; height: number };
}
export default class FilesService {
  static download = async (
    url: string,
    type: "image" | "video",
    imageResizeProperties: ImageResizeProperties,
    savePaths: string[]
  ): Promise<DownloadedFile> => {
    let res: any;

    try {
      if (type === "image") {
        res = await this.downloadImage(url, imageResizeProperties, savePaths);
      } else {
        res = await this.downloadVideo(url, savePaths);
      }
    } catch (err) {
      console.log("download error", url, err);
    }

    return res;
  };

  static async downloadVideo(
    url: string,
    savePaths: string[]
  ): Promise<DownloadedFile> {
    let response: any;
    let timeStart = Date.now();
    try {
      response = await axios.get(url, { responseType: "arraybuffer" });
    } catch (error) {
      throw new Error(`Failed to fetch ${url}: ${error.message}`);
    }
    let timeEnded = Date.now();

    const buffer = Buffer.from(response.data);
    const videoName = uuid() + ".mp4";
    const storageDir = process.env.STORAGE_PATH;
    const videoUPloadPath = path.join(...savePaths, videoName);
    const uploadResults = await this.uploadVideoToS3(buffer, videoUPloadPath);
    return {
      name: videoName,
      path: videoUPloadPath,
    };
  }

  static async uploadImageToS3(
    image: Jimp,
    s3Path: string
  ): Promise<string | any> {
    let buffer = await image.getBufferAsync(Jimp.MIME_JPEG);
    const s3 = new AWS.S3();
    const uploadParams = {
      Bucket: process.env.AWS_S3_BUCKET,
      Key: s3Path,
      Body: buffer,
      ACL: "public-read",
    };
    const result = await s3.upload(uploadParams).promise();
    return result;
  }

  static async uploadVideoToS3(
    buffer: any,
    s3Path: string
  ): Promise<string | any> {
    const s3 = new AWS.S3();
    const uploadParams = {
      Bucket: process.env.AWS_S3_BUCKET,
      Key: s3Path,
      Body: buffer,
      ContentType: "video/mp4",
      ACL: "public-read", // if you want the video to be publicly accessible
    };
    const result = await s3.upload(uploadParams).promise();
    return result;
  }

  static async cropToAspectRatio(
    image: any,
    aspectWidth: number,
    aspectHeight: number
  ) {
    const desiredRatio = aspectWidth / aspectHeight;
    const currentRatio = image.bitmap.width / image.bitmap.height;

    let cropWidth = image.bitmap.width;
    let cropHeight = image.bitmap.height;

    if (currentRatio > desiredRatio) {
      cropWidth = cropHeight * desiredRatio;
    } else if (currentRatio < desiredRatio) {
      cropHeight = cropWidth / desiredRatio;
    }

    const x = (image.bitmap.width - cropWidth) / 2;
    const y = (image.bitmap.height - cropHeight) / 2;

    return image.crop(x, y, cropWidth, cropHeight);
  }

  static async downloadImage(
    url: string,
    imageResizeProperties: {
      resizeWidth: number;
      aspectRation: { width: number; height: number };
    },
    savePaths: string[]
  ): Promise<DownloadedFile> {
    let image = await this.downloadRawImage(url);

    const newWidth = imageResizeProperties.resizeWidth;
    if (image.bitmap.width > newWidth) {
      image = await this.resizeImage(newWidth, image);
    }

    const imageName = uuid() + ".jpg";
    let storagePath = path.join(...savePaths, imageName);
    let s3UploadResults = await this.uploadImageToS3(image, storagePath);

    return {
      name: imageName,
      path: storagePath,
    };
  }

  static async downloadRawImage(url: string): Promise<Jimp> {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
    }
    const buffer = Buffer.from(await response.arrayBuffer());
    return await Jimp.read(buffer);
  }

  static async resizeImage(newWidth: number, image: Jimp): Promise<Jimp> {
    if (image.bitmap.width > newWidth) {
      image = image.resize(newWidth, Jimp.AUTO);
    }
    return image;
  }

  static saveImageToFile = async (
    image: Jimp,
    savePath: string
  ): Promise<string> => {
    try {
      const dirPath = path.dirname(savePath);
      await fsp.mkdir(dirPath, { recursive: true });
      await image.writeAsync(savePath);
      return savePath;
    } catch (err) {
      console.log("saveImageToFile:err", err);
    }

    return null;
  };

  static async saveVideoToFile(
    buffer: Buffer,
    filePath: string
  ): Promise<void> {
    const dirPath = path.dirname(filePath);
    await fsp.mkdir(dirPath, { recursive: true });
    return new Promise((resolve, reject) => {
      const bufferStream = new stream.PassThrough();
      bufferStream.end(buffer);

      const fileStream = fs.createWriteStream(filePath);
      bufferStream
        .pipe(fileStream)
        .on("error", (err) => {
          console.error("Error writing file:", err);
          reject(err);
        })
        .on("finish", () => {
          console.log("Video saved to", filePath);
          fileStream.close();
          resolve();
        });
    });
  }
}
