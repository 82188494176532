import prisma from "../../../../prisma";
import settingsService from "../settingsService";
import { createSessionCookie, verifyToken } from "../auth/SessionService";
import CommonUtils from "@/utils/CommonUtils";
import { TelegramService } from "../TelegramService";
import SellerService from "../seller/SellerService";

class UserService {
  public async upsertFBAccount(
    email: string,
    fbId: string,
    name: string,
    igAccount: any,
    access_token: string,
    access_token_expires_at_ms: number
  ) {
    {
      try {
        const access_token_expires_at = new Date(
          Date.now() + access_token_expires_at_ms * 1000
        );

        access_token_expires_at.setDate(access_token_expires_at.getDate() - 10);

        const user = await prisma.user.findFirst({
          where: { fbEmail: email },
        });

        let userRes = null;
        if (user) {
          userRes = await prisma.user.update({
            where: { email: email },
            data: {
              name: name,
              fbToken: access_token,
              fbTokenExpiresAt: access_token_expires_at,
              fb_token_last_use: new Date(),
              fb_user_id: fbId,
              fb_ig_account_id: igAccount.id,
              fb_ig_account_username: igAccount.username,
            },
          });
        } else {
          userRes = await prisma.user.create({
            data: {
              email: email,
              fbId: fbId,
              name: name,
              fbEmail: email,
              fbToken: access_token,
              fbTokenExpiresAt: access_token_expires_at,
              fb_token_last_use: new Date(),
              fb_user_id: fbId,
              fb_ig_account_id: igAccount.id,
              fb_ig_account_username: igAccount.username,
            },
          });
        }

        return { success: true, message: "User added as admin", user: userRes };
      } catch (error) {
        throw error;
      }
    }
  }

  public async upsertGoogleAccount(
    email: string,
    googleId: string,
    name: string
  ) {
    {
      try {
        let user = await prisma.user.findFirst({
          where: { email: email },
        });

        let userRes = null;
        if (user) {
          userRes = await prisma.user.update({
            where: { id: user.id },
            data: {
              name: name,
            },
          });
        } else {
          userRes = await prisma.user.create({
            data: {
              email: email,
              google_user_id: googleId,
              name: name,
            },
          });
          this.sendNewUserRegistration(name, email, "Google");
        }

        let sellerAccounts = await SellerService.getSellersByUserId(userRes.id);
        if (sellerAccounts.length === 0) {
          let s = await SellerService.createSellerAccount(
            userRes.id,
            userRes.name,
            email
          );
        }

        return { success: true, message: "User created", user: userRes };
      } catch (error) {
        throw error;
      }
    }
  }

  public async fbUserIsAdmin(email: string) {
    try {
      let fbAdmins = await settingsService.getSettingsByName("FB_ADMINS");

      let fbAdminsArray = fbAdmins.split(",");

      if (fbAdminsArray.indexOf(email) === -1) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      throw error;
    }
  }

  public async getUserByUuid(uuid: string) {
    try {
      let user = await prisma.user.findFirst({
        where: { uuid: uuid },
      });

      return user;
    } catch (error) {
      throw error;
    }
  }

  public async getLoggedInUser(req: any, additionalFields: any = {}) {
    const cookies = req.headers.cookie || "";
    const token = CommonUtils.getCookieValue(cookies, "token");

    let decodedToken = null;
    if (!token) {
      return null;
    }

    decodedToken = await verifyToken(token);

    if (!decodedToken) {
      return null;
    }

    let user: any;

    try {
      if (decodedToken.userType === "admin") {
        if (additionalFields.whatsapp_number) {
          delete additionalFields.whatsapp_number;
          delete additionalFields.thumbnail_path;
        }
        user = await prisma.user.findFirst({
          select: {
            id: true,
            name: true,
            email: true,
            uuid: true,
            active: true,
            fbId: true,
            fbEmail: true,
            fbToken: true,
            google_user_id: true,
            fbTokenExpiresAt: true,
            fb_token_last_use: true,
            fb_user_id: true,
            fb_ig_account_id: true,
            fb_ig_account_username: true,
            ...additionalFields,
          },
          where: { uuid: decodedToken.uuid },
        });
      } else {
        user = (await prisma.seller.findFirst({
          select: {
            id: true,
            name: true,
            uuid: true,
            active: true,
            ig_id: true,
            ig_name: true,
            ig_token: true,
            ig_token_expires_at: true,
            allow_sync: true,
            type: true,
            ...additionalFields,
          },
          where: { uuid: decodedToken.uuid },
        })) as any;

        let hasMultipleAccounts = decodedToken.accounts.length > 1;

        user = {
          ...user,
          hasMultipleAccounts,
        };
      }

      return user;
    } catch (error) {
      throw error;
    }
  }

  public async getLoggedInSellerUser(req: any, additionalFields: any = {}) {
    const cookies = req.headers.cookie || "";
    const token = CommonUtils.getCookieValue(cookies, "token");

    let decodedToken = null;
    if (!token) {
      return null;
    }

    decodedToken = await verifyToken(token);

    if (!decodedToken) {
      return null;
    }

    let user;

    try {
      user = await prisma.user.findFirst({
        select: {
          id: true,
          name: true,
          uuid: true,
          email: true,
          ...additionalFields,
        },
        where: { uuid: decodedToken.user_uuid },
      });

      return user;
    } catch (error) {
      throw error;
    }
  }

  public async getUserAccessTokenToUse() {
    try {
      let user = await prisma.user.findFirst({
        where: {
          fbToken: {
            not: null,
          },
          fbTokenExpiresAt: {
            gte: new Date(),
          },
          OR: [
            {
              fb_token_last_use: null,
            },
            {
              fb_token_last_use: {
                lte: new Date(),
              },
            },
          ],
        },
        orderBy: {
          fb_token_last_use: "asc",
        },
      });

      this.updateTokenLastUse(user.email);

      return user;
    } catch (error) {
      throw error;
    }
  }

  async updateTokenLastUse(email: string) {
    try {
      let user = await prisma.user.update({
        where: {
          email: email,
        },
        data: {
          fb_token_last_use: new Date(),
        },
      });

      return user;
    } catch (error) {
      throw error;
    }
  }

  async getUserByEmail(email: string) {
    try {
      let user = await prisma.user.findFirst({
        where: {
          email: email,
        },
      });

      return user;
    } catch (error) {
      throw error;
    }
  }

  sendNewUserRegistration(name: string, email: string, via: string) {
    const message = `New user registered via ${via}\n \nName: ${name}\n \nEmail: ${email}`;
    TelegramService.sendMessage(message);
  }

  async setLoggedInSeller(res: any, sellerUuid: string, userUuid: string) {
    let user = (await this.getUserByUuid(userUuid)) as any;
    if (!user) {
      throw new Error("User not found");
    }

    let seller = (await SellerService.getSellerByUuid(sellerUuid, {
      id: true,
      name: true,
      email_address: true,
      uuid: true,
      ig_id: true,
      ig_name: true,
    })) as any;

    if (!seller) {
      throw new Error("Seller not found");
    }

    let userType = "seller";

    let accounts = await SellerService.getSellersByUserId(user.id);

    if (accounts.length === 0) {
      throw new Error("No seller accounts found for user");
    }
    seller = {
      ...seller,
      email: seller.email_address,
      accounts: accounts.map((account) => account.uuid),
      user_uuid: user.uuid,
    };

    return createSessionCookie(res, seller, userType);
  }
}

export default new UserService();
