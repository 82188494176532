export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {})
        .catch((registrationError) => {});
    });
  }
};
