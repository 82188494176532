import axios from "axios";

export class TelegramService {
  public static async sendMessage(
    text: string,
    keyboard: any = []
  ): Promise<void> {
    try {
      this._sendMessage(text, keyboard);
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  }

  private static async _sendMessage(
    message: string,
    keyboard: any = []
  ): Promise<void> {
    const cid = process.env.TELEGRAM_CHAT_ID;
    const tkn = process.env.TELEGRAM_BOT_TOKEN;

    let data = {
      chat_id: cid,
      text: message,
    };

    if (keyboard?.length > 0) {
      data["reply_markup"] = {
        inline_keyboard: keyboard,
      };
    }

    const url = process.env.TELEGRAM_API_URL;

    const apiUrl = `${url}${tkn}/sendMessage`;
    try {
      const response = axios.post(apiUrl, data);
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  }
}
