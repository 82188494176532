import CommonUtils from "@/utils/CommonUtils";
import { verifyToken } from "./SessionService";

export const isPermitted = async (req: any, res: any) => {
  const url = req?.url;

  if (!url) {
    return false;
  }

  const cookies = req?.headers?.cookie || "";
  const token = CommonUtils.getCookieValue(cookies, "token");

  let decodedToken = null;
  if (token) {
    decodedToken = await verifyToken(token);
  }

  if (pathIsProtected(url) && !decodedToken) {
    return false;
  }

  return true;
};

const pathIsProtected = (url: string) => {
  const protectedPaths = ["/app-admin", "/agent-admin"];
  let isProtected = false;
  protectedPaths.forEach((path) => {
    if (url.indexOf(path) != -1) {
      isProtected = true;
      return true;
    }
  });
  return isProtected;
};
