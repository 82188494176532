import prisma from "../../../prisma";
import { TelegramService } from "./TelegramService";

class SettingsService {
  public async getSettingsByName(name: string) {
    const setting = await prisma.settings.findUnique({
      where: { name: name },
      select: { value: true },
    });
    let value = setting?.value;

    return value;
  }

  public async pauseFetching() {
    let pauseUntil = new Date();
    //pause for 20 minutes
    pauseUntil.setMinutes(pauseUntil.getMinutes() + 20);
    let pauseUntilDb = pauseUntil.toISOString();
    TelegramService.sendMessage(`Fetching paused until ${pauseUntilDb}`);
    return await prisma.settings.update({
      where: { name: "PAUSE_FETCHING_UNTIL" },
      data: { value: pauseUntilDb },
    });
  }

  public async resumeFetching() {
    return await prisma.settings.update({
      where: { name: "PAUSE_FETCHING_UNTIL" },
      data: { value: null },
    });
  }

  public async isFetchingPaused() {
    await this.resumeFetchingIfPauseTimePassed();
    const pauseUntil = await this.getSettingsByName("PAUSE_FETCHING_UNTIL");
    if (pauseUntil) {
      return new Date(pauseUntil) > new Date();
    }
    return false;
  }

  public async resumeFetchingIfPauseTimePassed() {
    const pauseUntil = await this.getSettingsByName("PAUSE_FETCHING_UNTIL");
    if (pauseUntil) {
      if (new Date(pauseUntil) < new Date()) {
        return await this.resumeFetching();
      }
    }
    return null;
  }
}

export default new SettingsService();
